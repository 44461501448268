.dropdown {
  position: relative;
  display: inline-block;

  &--btn {
    background-color: transparent;
    padding: 10px;
    border: none;
    border-radius: 10%;
    cursor: pointer;
    font-weight: 400;
    color:white;
    &:hover {
        background-color: #228B22;
    }
  }

  .currentPage{
    background-color: #228B22;
  }

  &-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }

  &:hover .dropdown-content {
    display: block;
  }
}
