body {
  font-family: "Playfair Display";
  // background: linear-gradient(to right, #FFD1DC, #BFAACB);
}

.video-gallery-wrap {
  // background-color: #FFC0CB;
  background: linear-gradient(to right, #e0f8d8, #f9f9d8, #ffffff);
  h2 {
    color: #333;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: 10px;
      padding-left: 20px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 10px;
        height: 10px;
        background-color: #daa520; // Gold color
        border-radius: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.video-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
    padding: 1rem;
  }
  
  @media (min-width: 1200px) { /* Large screens */
    .video-gallery {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  @media (max-width: 1199px) and (min-width: 900px) { /* Medium screens */
    .video-gallery {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 899px) and (min-width: 600px) { /* Small screens */
    .video-gallery {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 599px) { /* Extra small screens */
    .video-gallery {
      grid-template-columns: 1fr;
    }
  }