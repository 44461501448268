.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 1rem 8rem;
    z-index: 10;
    background-color: transparent; 
    transition: background-color .5s; 
    
    &--wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    @media (max-width: 768px) {
      padding: 0.5rem 2rem;
    }
  }

  .navbar-mobile-toggle {
    display: none;
    font-size: 24px;
    cursor: pointer;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .navbar-menu {
    @media (max-width: 768px) {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: rgba($color: #000000, $alpha: .5);;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0,0,0,0.1);

      &.active {
        display: block;
      }
    }
  }
  
  .navbar-sticky {
    color: black;
    background-color: rgba($color: #000000, $alpha: .5);
  }
  