.heading-page {
  text-align: center;
  width: fit-content;
  margin: 0 auto;
}
.heading-page--text-wrap {
  display: flex;
  justify-content: center;
  .heading-page--text {
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    color: #d4af37;
    letter-spacing: 2px;
    transition: all 0.2s;
    // background-image: linear-gradient(to right, #88c057, #ffd966);
    // background-clip: text;
    &:hover {
      transform: skewY(2deg) skewX(15deg);
      text-shadow: 0.5rem 0.7rem 2rem rgba(0, 0, 0, 0.2);
    }
  }
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
}
.heading-page--logo {
  width: 12rem;
  height: 12rem;
}
hr {
  margin: 2rem 12rem;
  border: none;
  height: 1px;
  background-color: pink;
  @media (max-width:768px){
    margin: 2rem 4rem;
  }
}
